<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Crear envío'" />

    <v-card height="75vh" class="mt-5">
      <v-card-text>
        <!-- Select partner -->
        <v-autocomplete
          v-show="!showTransferCalculator && !showTransfersSummary"
          v-model="selectedPartner"
          :items="partners"
          item-text="name"
          label="Partner"
          return-object
          @change="calculateCreditsTransfers"
        />
        <ProjectListForTransfer
          v-show="!showTransferCalculator && !showTransfersSummary"
          :selectedProjects="selectedProjects"
          :selectedPartner="selectedPartner"
          :projectsTransfer="projectsTransfer"
        />
        <TransfersSummary v-if="showTransfersSummary" :selectedProjects="selectedProjects" :selectedPartner="selectedPartner" />
        <TransferCalculator
          v-show="showTransferCalculator"
          ref="calculator"
          :selectedProjects="selectedProjects"
          :selectedPartner="selectedPartner"
        />
      </v-card-text>
    </v-card>
    <div class="d-flex justify-space-between mt-3">
      <v-btn :disabled="!showTransferCalculator && !showTransfersSummary" @click="btnBack()">Volver</v-btn>
      <v-btn
        v-if="!showTransferCalculator && !showTransfersSummary"
        :disabled="selectedProjects.length === 0"
        @click="showTransfersSummary = true"
      >
        Ver resumen
      </v-btn>
      <v-btn v-if="showTransfersSummary" @click="(showTransfersSummary = false), (showTransferCalculator = true)">calculadora</v-btn>
      <v-btn v-if="showTransferCalculator" @click="dialogCreateCreditTransfer = true">Crear transferencia</v-btn>
    </div>

    <!-- Dialog create credit transfer -->
    <DialogBox :model="dialogCreateCreditTransfer">
      <template slot="toolbar">
        <span>Crear envío</span>
      </template>
      <template slot="content">
        <v-checkbox v-model="isStatusSent" label="Crear envío con estado enviado" />
      </template>
      <template slot="actions">
        <v-btn text @click="dialogCreateCreditTransfer = false">Cerrar</v-btn>
        <v-btn text @click="(dialogCreateCreditTransfer = false), $refs.calculator.createMoneyTransfers(isStatusSent)">crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import Banner from "@/components/elements/Banner";
import ProjectListForTransfer from "@/components/elements/credit-transfers/ProjectListForTransfer.vue";
import TransfersSummary from "@/components/elements/credit-transfers/TransfersSummary.vue";
import TransferCalculator from "@/components/elements/credit-transfers/transfer-calculator/TransferCalculator.vue";
import DialogBox from "@/components/elements/DialogBox";

export default {
  name: "CreateCreditTransfers",
  components: {
    Banner,
    ProjectListForTransfer,
    TransfersSummary,
    TransferCalculator,
    DialogBox
  },
  data() {
    return {
      selectedPartner: {},
      selectedProjects: [],
      projectsTransfer: [],
      showTransferCalculator: false,
      showTransfersSummary: false,
      dialogCreateCreditTransfer: false,
      isStatusSent: false
    };
  },
  computed: {
    ...mapState({
      partners: (state) => state.partners.partners
    })
  },
  async created() {
    await this.$store.dispatch("partners/getPartners");
    await this.$store.dispatch("currencies/getCurrencies");
  },
  methods: {
    async calculateCreditsTransfers() {
      this.$store.commit("offices/SET_OFFICES", []);
      this.selectedProjects = this.$options.data().selectedProjects;
      this.projectsTransfer = await this.$store.dispatch("creditTransfers/calculateCreditsTransfers", this.selectedPartner.id);

      const projectsTransferOfficesIds = [];
      for (const project of this.projectsTransfer) {
        if (!projectsTransferOfficesIds.some((e) => e === project.office_id)) {
          projectsTransferOfficesIds.push(project.office_id);
        }
      }
      if (projectsTransferOfficesIds.length > 0) {
        await this.$store.dispatch("offices/getOffices", { filter: { id: projectsTransferOfficesIds } });
      }
    },

    btnBack() {
      this.$refs.calculator.resetOfficeList();
      if (this.showTransfersSummary) this.showTransfersSummary = false;
      if (this.showTransferCalculator) {
        this.showTransferCalculator = false;
        this.showTransfersSummary = true;
      }
    }
  }
};
</script>

<style scoped>
.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
