<template>
  <div>
    <v-select
      v-model="selectedOffices"
      :items="officesAvailablesToSelect"
      item-text="name"
      label="Oficinas"
      multiple
      filled
      class="mb-5"
      return-object
      append-outer-icon="add"
      @click:append-outer="addOffices"
    />

    <div v-for="(pack, i) in _.values(officeList)" :key="i">
      <SingleOffice v-if="pack.length === 1" :pack="pack[0]" :selectedProjects="selectedProjects" @removePack="removePack" class="mb-10" />
      <MultipleOffices v-if="pack.length > 1" :pack="pack" :selectedProjects="selectedProjects" @removePack="removePack" class="mb-10" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import CommonMixin from "@/mixins/CommonMixin";
import SingleOffice from "@/components/elements/credit-transfers/transfer-calculator/SingleOffice.vue";
import MultipleOffices from "@/components/elements/credit-transfers/transfer-calculator/MultipleOffices.vue";
import * as currency from "currency.js";

export default {
  name: "TransferCalculator",
  components: { SingleOffice, MultipleOffices },
  mixins: [CommonMixin],
  props: {
    selectedPartner: {
      type: Object,
      required: true
    },
    selectedProjects: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedOffices: [],
      officeList: {}
    };
  },
  computed: {
    ...mapState({
      offices: (state) => state.offices.offices
    }),
    _() {
      return _;
    },

    filteredOfficesByPartnerAndSelectedProjects() {
      const offices = this.offices.filter((e) => this.selectedProjects.some((j) => j.office_id === e.id));
      return offices;
    },

    officesAvailablesToSelect() {
      const availables = [];

      for (const office of this.filteredOfficesByPartnerAndSelectedProjects) {
        let isAlreadySelected = false;
        for (const pack of this._.values(this.officeList)) {
          if (pack.some((e) => e.id === office.id)) isAlreadySelected = true;
        }
        if (!isAlreadySelected) {
          const officeCloned = this._.cloneDeep(office);
          availables.push(officeCloned);
        }
      }

      return availables;
    }
  },
  methods: {
    addOffices() {
      if (this.selectedOffices.length > 0) {
        const key = this.makeid(20);
        this.$set(this.officeList, key, this.selectedOffices);
        this.selectedOffices = [];
      }
    },

    resetOfficeList() {
      this.officeList = this.$options.data().officeList;
    },

    removePack(officeId) {
      for (const key of this._.keys(this.officeList)) {
        for (const office of this.officeList[key]) {
          if (office.id === officeId) this.$delete(this.officeList, key);
        }
      }
    },

    // Check if amountToSend is equal to compensationFees + compensationOutlays + amountToTransfer
    checkAmounts() {
      for (let i = 0; i < this._.values(this.officeList).length; i++) {
        const pack = this._.values(this.officeList)[i];
        const amountToSend = currency(pack[pack.length - 1].calculatedData.amountToSend).value;

        const bankAmountAvailable = currency(pack[pack.length - 1].calculatedData.bankAmountAvailable).value;
        const amountToTransfer = pack[pack.length - 1].calculatedData.amountToTransfer;
        const compensationFees = pack[pack.length - 1].calculatedData.compensationFees;
        const compensationOutlays =
          pack[pack.length - 1].calculatedData.compensationOutlays?.value ?? pack[pack.length - 1].calculatedData.compensationOutlays;

        console.log("-- amountToSend --", amountToSend);
        console.log("bankAmountAvailable: ", bankAmountAvailable);
        console.log("amountToTransfer:", amountToTransfer);
        console.log("compensationFees:", compensationFees);
        console.log("compensationOutlays:", compensationOutlays);
        console.log("TOTAL = ", amountToTransfer + compensationFees + compensationOutlays + bankAmountAvailable);

        if (amountToSend > amountToTransfer + compensationFees + compensationOutlays + bankAmountAvailable) return false;
      }

      return true;
    },

    async createMoneyTransfers(isStatusSent, isProjectBag = false, projectBagId) {
      let areAllCalculated = true;

      for (const pack of this._.values(this.officeList)) {
        if (pack.length === 1) {
          for (const office of pack) {
            if (!office.calculatedData) areAllCalculated = false;
          }
        } else if (pack.length > 1) {
          if (this._.size(pack[pack.length - 1].calculatedData) === 0) areAllCalculated = false;
        }
      }

      if (this._.size(this.officeList) && areAllCalculated && this.officesAvailablesToSelect.length === 0 && this.checkAmounts()) {
        const transfersData = [];
        for (const pack of this._.values(this.officeList)) {
          const transferData = {
            offices_ids: [],
            accounts: [],
            credits_transfers: [],
            amount_sent: 0,
            bank_amount_available: 0,
            amount_to_send: 0,
            amount_to_transfer: 0,
            compensation_fees: 0,
            compensation_outlays: 0,
            partner_id: "",
            status: ""
          };

          if (this._.keys(pack).length === 1) {
            for (const office of pack) {
              transferData.offices_ids.push(office.id);
              transferData.accounts = office.accounts;
              transferData.credits_transfers = this.selectedProjects.filter((e) => e.office_id === office.id);
              transferData.amount_sent = office.calculatedData.amountSent;
              transferData.bank_amount_available = office.calculatedData.bankAmountAvailable;
              transferData.amount_to_send = office.calculatedData.amountToSend;
              transferData.amount_to_transfer = office.calculatedData.amountToTransfer;
              transferData.compensation_fees = office.calculatedData.compensationFees;
              transferData.compensation_outlays =
                office.calculatedData.compensationOutlays?.value ?? office.calculatedData.compensationOutlays;
              transferData.partner_id = this.selectedPartner.id;
              transferData.status = isStatusSent ? "SENT" : "CREATED";

              transfersData.push(transferData);
            }
          } else if (this._.keys(pack).length > 1) {
            for (let i = 0; i < pack.length - 1; i++) {
              const office = pack[i];
              transferData.offices_ids.push(office.id);
            }
            transferData.accounts = pack[pack.length - 1].accounts;
            transferData.credits_transfers = this.selectedProjects;
            transferData.amount_sent = pack[pack.length - 1].calculatedData.amountSent;
            transferData.bank_amount_available = pack[pack.length - 1].calculatedData.bankAmountAvailable;
            transferData.amount_to_send = pack[pack.length - 1].calculatedData.amountToSend;
            transferData.amount_to_transfer = pack[pack.length - 1].calculatedData.amountToTransfer;
            transferData.compensation_fees = pack[pack.length - 1].calculatedData.compensationFees;
            transferData.compensation_outlays =
              pack[pack.length - 1].calculatedData.compensationOutlays?.value ?? pack[pack.length - 1].calculatedData.compensationOutlays;
            transferData.partner_id = this.selectedPartner.id;
            transferData.status = isStatusSent ? "SENT" : "CREATED";

            transfersData.push(transferData);
          }
        }

        // Delete innceseray properties
        const transfersDataFormatted = this._.cloneDeep(transfersData);

        for (const data of transfersDataFormatted) {
          data.amount_to_send = currency(data.amount_to_send).value;
          for (let i = 0; i < data.accounts.length; i++) {
            const account = data.accounts[i];
            this.$delete(account, "id");
            this.$delete(account, "clabe");
            if (account.type != "FEES" || account.type != "OUTLAYS") data.accounts.slice(i, 1);
          }
          for (const credit_transfer of data.credits_transfers) {
            for (const transfer of credit_transfer.transfers) {
              transfer.amount_converted = currency(transfer.amount_converted).intValue;
              this.$delete(transfer, "investor_first_name");
              this.$delete(transfer, "investor_last_name");
              this.$delete(transfer, "investor_type");
            }
          }
        }

        if (isProjectBag)
          await this.$store.dispatch("projectBags/createMoneyTransfersInBag", {
            id: projectBagId,
            money_transfers: transfersDataFormatted
          });
        else {
          await this.$store.dispatch("creditTransfers/createMoneyTransfers", transfersDataFormatted);
          this.$store.commit("offices/SET_OFFICES", []);
        }

        this.$router.push("/envios");
      } else {
        let errorMessage = "";
        if (!this._.size(this.officeList) || this.officesAvailablesToSelect.length !== 0) errorMessage = "All offices must be selected";
        else if (!areAllCalculated) errorMessage = "All offices must be calculated";
        else if (!this.checkAmounts()) {
          errorMessage =
            "Disponible en banco + transferencia a realizar + compensación cuentas cuotas + compensación cuentas desembolsos debe ser mayor o igual a dinero envío";
        }
        this.$store.commit("SET_SNACKBAR", { show: true, text: errorMessage, color: "error" });
      }
    }
  }
};
</script>
