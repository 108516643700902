<template>
  <v-card>
    <v-toolbar color="primary" dense>
      <span>{{ officesNames }}</span>
      <v-spacer />
      <v-icon color="red" @click="$emit('removePack', officesIds[0])">delete</v-icon>
    </v-toolbar>
    <v-card-text>
      <!-- Accounts table -->
      <v-simple-table class="accountsTable" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th>Tipo de cuenta</th>
              <th>Disponible</th>
              <th>Fianza</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in pack[pack.length - 1].accounts" :key="account.id">
              <td>{{ account.type }}</td>
              <td>
                <v-text-field v-model.number="account.balance" type="number" filled dense class="mt-2 mb-n4" />
              </td>
              <td>
                <v-text-field v-model.number="account.bail" type="number" filled dense class="mt-2 mb-n4" />
              </td>
            </tr>
            <tr class="blue lighten-4">
              <td colspan="1">TOTAL</td>
              <td colspan="2" class="text-center">{{ totalAccountsAvailable() }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-btn block color="grey lighten-1" class="rounded-t-0" width="100%" @click="calculateData()">Calcular</v-btn>

      <!-- Calculated data table -->
      <v-simple-table v-if="this._.keys(pack[pack.length - 1].calculatedData).length > 0" dense class="mt-5">
        <template v-slot:default>
          <tbody>
            <tr>
              <th>Enviado:</th>
              <td>
                {{ round(pack[pack.length - 1].calculatedData.amountSent) }}
              </td>
            </tr>
            <tr>
              <th>Disponible en banco:</th>
              <td>
                {{ round(pack[pack.length - 1].calculatedData.bankAmountAvailable) }}
              </td>
            </tr>
            <tr>
              <th>Dinero envío:</th>
              <td>
                {{ round(pack[pack.length - 1].calculatedData.amountToSend) }}
              </td>
            </tr>
            <tr>
              <th>Transferencia a realizar:</th>
              <td>
                <v-text-field
                  v-model.number="pack[pack.length - 1].calculatedData.amountToTransfer"
                  type="number"
                  filled
                  dense
                  class="mt-2 mb-n4"
                />
              </td>
            </tr>
            <tr>
              <th>Compensación cuentas cuotas:</th>
              <td>
                <v-text-field
                  v-model.number="pack[pack.length - 1].calculatedData.compensationFees"
                  type="number"
                  filled
                  dense
                  class="mt-2 mb-n4"
                />
              </td>
            </tr>
            <tr>
              <th>Compensación cuentas desembolsos:</th>
              <td>
                <v-text-field
                  v-model.number="pack[pack.length - 1].calculatedData.compensationOutlays"
                  type="number"
                  filled
                  dense
                  class="mt-2 mb-n4"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import CommonMixin from "@/mixins/CommonMixin";
import * as currency from "currency.js";

export default {
  name: "MultipleOffices",
  mixins: [CommonMixin],
  props: {
    pack: {
      type: Array,
      required: true
    },
    selectedProjects: {
      type: Array,
      required: true
    }
  },
  computed: {
    _() {
      return _;
    },

    officesNames() {
      let names = "Oficinas: ";

      for (let i = 0; i < this.pack.length - 1; i++) {
        const office = this.pack[i];
        names += i + 1 === this.pack.length - 1 ? `${office.name}` : `${office.name}, `;
      }
      return names;
    },

    officesIds() {
      const arrayIds = [];
      for (let i = 0; i < this.pack.length - 1; i++) {
        const office = this.pack[i];
        arrayIds.push(office.id);
      }

      return arrayIds;
    }
  },
  created() {
    this.pack.push({
      accounts: [
        {
          type: "FEES",
          balance: 0,
          bail: 0
        },
        {
          type: "OUTLAYS",
          balance: 0,
          bail: 0
        }
      ],
      calculatedData: {}
    });
  },
  methods: {
    ...mapActions({
      getTotalAmountSentByOffice: "creditTransfers/getTotalAmountSentByOffice"
    }),

    // Return total amount available from balance + bail of each account
    totalAccountsAvailable() {
      let totalBalance = 0;
      let totalBail = 0;

      for (const account of this.pack[this.pack.length - 1].accounts) {
        totalBalance += account.balance;
        totalBail += account.bail;
      }

      return totalBalance - totalBail;
    },

    // Calculate data of an office
    async calculateData() {
      const params = {
        amountSent: await this.getAmountSent(),
        bankAmountAvailable: await this.getBankAmountAvailable(),
        amountToSend: this.getAmountToSend(),
        amountToTransfer: await this.getAmountToTransfer(),
        compensationFees: await this.getCompensationFees(),
        compensationOutlays: await this.getCompensationOutlays()
      };
      this.pack[this.pack.length - 1].calculatedData = params;
    },

    // ---------------------- Calculated data ----------------------
    async getAmountSent() {
      const amountSent = await this.getTotalAmountSentByOffice(this.officesIds);
      return amountSent;
    },

    async getBankAmountAvailable() {
      const amountSent = await this.getTotalAmountSentByOffice(this.officesIds);
      const bankAmountAvailable = amountSent + this.totalAccountsAvailable();

      if (bankAmountAvailable >= 0) return bankAmountAvailable;
      else return 0;
    },

    getAmountToSend() {
      let amount = 0;

      for (const project of this.selectedProjects.filter((e) => this.officesIds.includes(e.office_id))) {
        for (const transfer of project.transfers) {
          amount = currency(amount).add(currency(transfer.amount_converted));
        }
      }

      return currency(amount);
    },

    async getAmountToTransfer() {
      const amountToSend = this.getAmountToSend();
      const bankAmountAvailable = await this.getBankAmountAvailable();

      if (amountToSend - bankAmountAvailable >= 0) return amountToSend - bankAmountAvailable;
      else return 0;
    },

    async getCompensationFees() {
      // Get total available in FEES accounts - bail
      let totalBalance = 0;
      let totalBail = 0;

      for (const account of this.pack[this.pack.length - 1].accounts) {
        if (account.type === "FEES") {
          totalBalance += account.balance;
          totalBail += account.bail;
        }
      }

      const availableInFeesAccounts = totalBalance - totalBail;

      // Return all the available if it is not enough with what is available in OUTLAYS accounts
      const availableInOutlaysAccounts = await this.getCompensationOutlays();
      const amountToSend = this.getAmountToSend();

      if (amountToSend > availableInOutlaysAccounts) {
        const amountToSendWithoutAvailableInOutlaysAccounts = amountToSend - availableInOutlaysAccounts;

        if (availableInFeesAccounts > 0) {
          return availableInFeesAccounts >= amountToSendWithoutAvailableInOutlaysAccounts
            ? amountToSendWithoutAvailableInOutlaysAccounts
            : availableInFeesAccounts;
        } else return 0;
      } else return 0;
    },

    async getCompensationOutlays() {
      // Get total available in OUTLAUS accounts - (bail and already sent)
      let totalBalance = 0;
      let totalBail = 0;
      const amountSent = await this.getTotalAmountSentByOffice(this.officesIds);

      for (const account of this._.values(this.pack[this.pack.length - 1].accounts)) {
        if (account.type === "OUTLAYS") {
          totalBalance += account.balance;
          totalBail += account.bail;
        }
      }

      const availableInOutlaysAccounts = totalBalance - totalBail - amountSent;

      // Return all available in OUTLAUS accounts as long as it's not more than necessary
      const amountToSend = this.getAmountToSend();

      if (availableInOutlaysAccounts > 0) {
        return availableInOutlaysAccounts >= amountToSend ? amountToSend : availableInOutlaysAccounts;
      } else return 0;
    }
  }
};
</script>

<style scoped>
.accountsTable tbody td:first-child {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.accountsTable tbody td {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
